<template>
  <div :style="{backgroundColor: $route.query.backgroundColor ||'#eee'}" class="container prow-40">
    <h1 class="title pt-20 pb-10">《幼享乐服务协议》</h1>
    <div class="f-fs-fe f-col date">
      <span>生效发布日期：2021年08月16日 </span>
      <span>更新日期：2021年08月16日 </span>
    </div>
    <div class="txt">
      <div class="mrow-10">
        幼享乐是广东柏斯融媒体科技有限公司(以下简称柏斯融媒体科技)开发的软件，为使用幼享乐（以下简称“本软件”）及服务，你应当阅读并遵守《服务协议》（以下简称“本协议”）。
        请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、管辖与法律适用条款，以及开通或使用某项服务的协议。除非您已阅读并接受本协议所有条款，否则你无权下载、安装或使用本软件及相关服务。你的下载、安装、使用、获取幼享乐帐号、登录等行为即视为你已阅读并同意上述协议的约束。如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。
      </div>
      <h2 class="ul-title pt-20 pb-10">一、协议的范围</h2>
      <div class="mrow-10">
        本协议是你与柏斯融媒体科技之间关于你下载、安装、使用、复制本软件，以及使用柏斯融媒体科技相关软件和服务所订立的协议。“柏斯融媒体科技”是指广东柏斯融媒体科技有限公司及其相关服务可能存在的运营关联单位。“用户”是指使用柏斯融媒体科技相关服务的使用人，在本协议中更多地称为“您”。
      </div>
      <h2 class="ul-title pt-20 pb-10">二、服务内容和许可范围</h2>
      <div class="mrow-10">
        <h3 class="li-title">2.1 服务的内容</h3>
        <p class="ti-1">
          服务内容是指柏斯融媒体科技向用户提供的移动平台App（即“幼享乐”），支持单人、多人参与互动，在基于地理位置服务的基础上提供包括但不限于记录、用户之间即时通讯、用户之间的信息传播、网络媒体、互联网增值、互动娱乐、电子商务和广告等产品及服务（以下简称“本服务”）。你可以直接从柏斯融媒体科技的网站上获取本软件，也可以从得到柏斯融媒体科技授权的第三方获取。如果你在未经柏斯融媒体科技授权的第三方获取本软件或与本软件名称相同的安装程序，柏斯融媒体科技无法保证该软件能够正常使用，并对因此给你造成的损失不予负责。
        </p>
        <h3 class="li-title">2.2 许可的范围</h3>
        <p class="ti-1">
          你使用本服务需要下载幼享乐客户端软件，对于这些软件，柏斯融媒体科技给予你一项个人的、不可转让及非排他性的许可。幼享乐软件登录时使用你的手机号码、或微信、微博、QQ作为用户名登录，你仅可为访问或使用本服务的目的而使用这些软件及服务。本服务中幼享乐客户端软件提供包括但不限于iOS、Android、Windows
          Phone、Symbian、BlackBerry、Windows、Mac等多个应用版本，用户必须选择与所安装终端设备相匹配的软件版本。为提供更加优质、安全的服务，在本软件安装时柏斯融媒体科技可能推荐你安装其他软件，你可以选择安装或不安装。
          你可以为使用本软件及服务的目的复制本软件的一个副本，仅用作备份。备份副本必须包含原软件中含有的所有著作权信息。本条及本协议其他条款未明示授权的其他一切权利仍由柏斯融媒体科技保留，你在行使这些权利时须另外取得柏斯融媒体科技的书面许可。柏斯融媒体科技如果未行使前述任何权利，并不构成对该权利的放弃。
        </p>
        <h3 class="li-title">2.3 服务和软件的更新</h3>
        <p class="ti-1">
          为了改善用户体验、完善服务内容，柏斯融媒体科技将不断努力开发新的服务，并为你提供不定期的软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。为了保证本软件及服务的安全性和功能的一致性，柏斯融媒体科技有权不经向你特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。本软件新版本发布后，旧版本的软件可能无法使用。柏斯融媒体科技不保证旧版本软件继续可用及相应的客户服务，请你随时核对并下载最新版本。
        </p>
      </div>
      <h2 class="ul-title pt-20 pb-10">三、用户个人信息保护</h2>
      <div class="mrow-10">
        3.1
        保护用户个人信息是柏斯融媒体科技的一项基本原则，柏斯融媒体科技将会采取合理的措施保护用户的个人信息。除法律法规规定的情形外，未经用户许可柏斯融媒体科技不会向第三方公开、透露用户个人信息。柏斯融媒体科技对相关信息采用专业加密存储与传输方式，保障用户个人信息的安全。
        <br />
        3.2
        你在注册帐号或使用本服务的过程中，需要提供一些必要的信息，例如：为向你提供帐号注册服务或进行用户身份识别，需要你填写手机号码；附近的人功能需要你同意使用你所在的地理位置信息；手机通讯录匹配功能需要你授权访问手机通讯录等。若国家法律法规或政策有特殊规定的，你需要提供真实的身份信息。若你提供的信息不完整，则无法使用本服务或在使用过程中受到限制。
        <br />
        3.3
        一般情况下，你可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，你可能无法修改注册时提供的初始注册信息及其他验证信息。
        <br />
        3.4
        柏斯融媒体科技将运用各种安全技术和程序建立完善的管理制度来保护你的个人信息，以免遭受未经授权的访问、使用或披露。
        <br />
        3.5
        未经你的同意，柏斯融媒体科技不会向柏斯融媒体科技以外的任何公司、组织和个人披露你的个人信息，但法律法规另有规定的除外。
        <br />
        3.6柏斯融媒体科技非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用柏斯融媒体科技的服务前，应事先取得您家长或法定监护人（以下简称"监护人"）的书面同意。
        <br />
        3.7
        柏斯融媒体科技可能会与幼享乐的其它用户分享你的信息，以方便他们向你提供你申请的有关服务，例如，我们会与你聊天的一个或者多个对象分享你的昵称、照片（如果你提供）、影像（如果你提供）、用户的评级等信息。
        <br />
        3.8您在使用柏斯融媒体科技或幼享乐的服务时需要注册一个帐号。科技特别提醒您应妥善保管您的帐号和密码。当您使用完毕后，应安全退出。因您保管不善可能导致遭受盗号或密码失窃，责任由您自行承担。
      </div>
      <h2 class="ul-title pt-20 pb-10">四、软件使用和用户行为规范</h2>
      <div class="mrow-10">
        1、幼享乐帐号可通过手机号码或微信、微博、QQ进行注册，第三方帐号注册成功后需要绑定手机号。柏斯融媒体科技有权根据用户需求或产品需要对帐号注册和绑定的方式进行变更，关于你使用帐号的具体规则，请遵守国家想着法律规定、相关帐号使用协议以及柏斯融媒体科技为此发布的专项规则。幼享乐帐号的所有权归柏斯融媒体科技公司所有，用户完成申请注册手续后，仅获得幼享乐帐号的使用权，且该使用权仅属于初始申请注册人。同时，初始申请注册人不得赠与、借用、租用、转让或售卖幼享乐帐号或者以其他方式许可非初始申请注册人使用幼享乐帐号。非初始申请注册人不得通过受赠、继承、承租、受让或者其他任何方式使用幼享乐帐号。
        <br />
        2、用户有责任妥善保管注册帐户信息及帐户密码的安全，用户需要对注册帐户以及密码下的行为承担法律责任。用户同意在任何情况下不向他人透露帐户及密码信息。当在你怀疑他人在使用你的帐号时，你应立即通知柏斯融媒体科技公司。
        <br />
        3、你理解并同意：为了向你提供有效的服务，本软件会利用你终端设备的处理器和带宽等资源。本软件使用过程中可能产生数据流量的费用，用户需自行向运营商了解相关资费信息，并自行承担相关费用。本软件的某些功能可能会让第三方知晓用户的信息，例如：可以查询用户头像、昵称等可公开的个人资料；使用手机号码注册幼享乐帐号或手机号码与幼享乐帐号关联的用户，幼享乐帐号可以被通讯录存有该号码的好友查询；注册用户的评论内容、上传的图片、视频都可以被具备相应功能权限的用户在客户端查看。
        <br />
        4、你理解并同意：柏斯融媒体科技将会尽其商业上的合理努力保障你在本软件及服务中的数据存储安全，但是，柏斯融媒体科技并不能就此提供完全保证，包括但不限于以下情形：柏斯融媒体科技不对你在本软件及服务中相关数据的删除或储存失败负责；柏斯融媒体科技有权根据实际情况自行决定单个用户在本软件及服务中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。你可根据自己的需要自行备份本软件及服务中的相关数据；如果你停止使用本软件及服务或服务被终止或取消，柏斯融媒体科技可以从服务器上永久地删除你的数据。服务停止、终止或取消后，柏斯融媒体科技没有义务向你返还任何数据。
        <br />
        5、用户在使用本软件及服务时，须自行承担如下来自柏斯融媒体科技不可掌控的风险内容，包括但不限于：由于不可抗拒因素可能引起的个人信息丢失、泄漏等风险；用户必须选择与所安装终端设备相匹配的软件版本，否则，由于软件与终端设备型号不相匹配所导致的任何问题或损害，均由用户自行承担；用户在使用本软件访问第三方网站时，因第三方网站及相关内容所可能导致的风险，由用户自行承担；用户提交的内容被他人转发、分享，因此等传播可能带来的风险和责任；由于无线网络信号不稳定、无线网络带宽小等原因，所引起的登录失败、资料同步不完整、页面打开速度慢等风险。
        <br />
        6、你在使用本软件第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。柏斯融媒体科技和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。因用户使用本软件或要求柏斯融媒体科技提供特定服务时，本软件可能会调用第三方系统或者通过第三方支持用户的使用或访问，使用或访问的结果由该第三方提供（包括但不限于第三方通过幼享乐帐号提供的服务，或通过开放平台接入的内容等），柏斯融媒体科技不保证通过第三方提供服务及内容的安全性、准确性、有效性及其他不确定的风险，由此若引发的任何争议及损害，与柏斯融媒体科技无关，柏斯融媒体科技不承担任何责任。
        <br />
        7、你理解并同意，柏斯融媒体科技有权决定将本软件作商业用途，包括但不限于开发、使用本软件的部分服务为第三方作推广等，柏斯融媒体科技承诺在推广过程中严格按照本协议约定保护你的个人信息，同时你亦可以根据系统设置选择屏蔽、拒绝接收相关推广信息。
        <br />
        8、信息内容规范：本条所述信息内容是指用户使用本软件及服务过程中所制作、复制、发布、传播的任何内容，包括但不限于幼享乐帐号头像、名字、用户说明等注册信息，或文字、视频、图片等发送、回复、图文和相关链接页面，以及其他使用幼享乐帐号或本软件及服务所产生的内容。你理解并同意，幼享乐一直致力于为用户提供文明健康、规范有序的网络环境，你不得利用幼享乐帐号或本软件及服务制作、复制、发布、传播如下干扰幼享乐正常运营，以及侵犯其他用户或第三方合法权益的内容，包括但不限于：
        <br />
        <div class="mrow-10 tip">
          1）发布、传送、传播、储存违反国家法律法规禁止的内容：
          <br />
          2）发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
          <br />
          3）涉及他人隐私、个人信息或资料的；
          <br />
          4）其他违反法律法规、政策及公序良俗、社会公德或干扰幼享乐正常运营和侵犯其他用户或第三方合法权益内容的信息。
        </div>
        9、除非法律允许或柏斯融媒体科技书面许可，你使用本软件过程中不得从事下列行为：
        <div class="mrow-10 tip">
          1）删除本软件及其副本上关于著作权的信息；
          <br />
          2）对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；
          <br />
          3）对柏斯融媒体科技拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
          <br />
          4）对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非柏斯融媒体科技经授权的第三方工具/服务接入本软件和相关系统；
          <br />
          5）通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
          <br />
          6）通过非柏斯融媒体科技开发、授权的第三方软件、插件、外挂、系统，登录或使用幼享乐软件及服务，或制作、发布、传播上述工具；
          <br />
          7）自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰；
          <br />
          8）其他未经柏斯融媒体科技明示授权的行为。
        </div>
        10、除非法律允许或柏斯融媒体科技书面许可，你使用本服务过程中不得从事下列行为：
        <div class="mrow-10 tip">
          1）提交、发布虚假信息，或冒充、利用他人名义的；
          <br />
          2）诱导其他用户点击链接页面或分享信息的；
          <br />
          3）虚构事实、隐瞒真相以误导、欺骗他人的；
          <br />
          4）侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；
          <br />
          5）未经柏斯融媒体科技书面许可利用幼享乐帐号和任何功能，以及第三方运营平台进行推广或互相推广的；
          <br />
          6）利用幼享乐帐号或本软件及服务从事任何违法犯罪活动的；
          <br />
          7）制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；
          <br />
          8）其他违反法律法规规定、侵犯其他用户合法权益、干扰产品正常运营或柏斯融媒体科技未明示授权的行为。
        </div>
        11、你充分了解并同意，你必须为自己注册帐号下的一切行为负责，包括你所发表的任何内容以及由此产生的任何后果。你应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。柏斯融媒体科技无法且不会对因前述风险而导致的任何损失或损害承担责任。
        <br />
        12、如果柏斯融媒体科技发现或收到他人举报或投诉用户违反本协议约定的，柏斯融媒体科技有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直至注销的处罚，并公告处理结果。
        <br />
        13、你理解并同意，柏斯融媒体科技有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，用户应独自承担由此而产生的一切法律责任。
        <br />
        14、你理解并同意，因你违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，你应当独立承担责任；柏斯融媒体科技因此遭受损失的，你也应当一并赔偿。
      </div>
      <h2 class="ul-title pt-20 pb-10">五、知识产权声明</h2>
      <div class="mrow-10">
        1、柏斯融媒体科技是本软件的知识产权权利人。本软件的一切著作权、商标权、专利权、商业秘密等知识产权，以及与本软件相关的所有信息内容（包括但不限于文字、图片、音频、视频、图表、界面设计、版面框架、有关数据或电子文档等）均受中华人民共和国法律法规和相应的国际条约保护，柏斯融媒体科技在本服务中所使用的“柏斯融媒体科技”、“幼享乐”等商业标识，其著作权或商标权归柏斯融媒体科技所有。
        <br />
        2、幼享乐软件中的所有照片或视频，无论是柏斯融媒体科技上传提供或者用户上传提供，均不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权、肖像权、隐私权、物权或其他任何合法权益。影像著作权归拍摄人所有，照片中主体自然人的肖像权归被拍摄个人所有。用户有权提出将上传的照片作品删除，在收到提交的删除申请后，幼享乐将在合理期限内删除展示在幼享乐的相应作品。
        <br />
        3、在未经柏斯融媒体科技或被拍摄人许可前，任何机构和个人不得将照片或视频用于商业目的。使用图片或视频过程中，用户不得侵犯他人肖像权。不得违反国家相关法律规定。严禁将图片或视频用于危害社会、国家、他人的用途。
      </div>
      <h2 class="ul-title pt-20 pb-10">六、终端安全责任</h2>
      <div class="mrow-10">
        1、你理解并同意，本软件同大多数互联网软件一样，可能会受多种因素影响，包括但不限于用户原因、网络服务质量、社会环境等；也可能会受各种安全问题的侵扰，包括但不限于他人非法利用用户资料，进行现实中的骚扰；用户下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁你的终端设备信息和数据安全，继而影响本软件的正常使用等。因此，你应加强信息安全及个人信息的保护意识，注意密码保护，以免遭受损失。
        <br />
        2、你不得制作、发布、使用、传播用于窃取幼享乐帐号及他人个人信息、财产的恶意程序。
        <br />
        3、维护软件安全与正常使用是柏斯融媒体科技和你的共同责任，柏斯融媒体科技将按照行业标准合理审慎地采取必要技术措施保护你的终端设备信息和数据安全，但是你承认和同意柏斯融媒体科技并不能就此提供完全保证。
        <br />
        4、在任何情况下，你不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意柏斯融媒体科技有关防范诈骗犯罪的提示。
      </div>
      <h2 class="ul-title pt-20 pb-10">七、第三方软件或技术</h2>
      <div class="mrow-10">
        1、本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。
        <br />
        2、本软件如果使用了第三方的软件或技术，柏斯融媒体科技将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包、或通过开源软件页面等形式进行展示，它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。前述通过各种形式展现的相关协议、其他文件及网页，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，你应当遵守这些要求。如果你没有遵守这些要求，该第三方或者国家机关可能会对你提起诉讼、罚款或采取其他制裁措施，并要求柏斯融媒体科技给予协助，你应当自行承担法律责任。
        <br />
        3、如因本软件使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，柏斯融媒体科技不承担任何责任。柏斯融媒体科技不对第三方软件或技术提供客服支持，若你需要获取支持，请与第三方联系。
      </div>
      <h2 class="ul-title pt-20 pb-10">八、广告和收费</h2>
      <div class="mrow-10">
        1、您同意柏斯融媒体科技可以在提供服务的过程中自行或由第三方广告商向您发送广告、推广或宣传信息（包括商业与非商业信息），其方式和范围可不经向您特别通知而变更。柏斯融媒体科技可能为您提供选择关闭广告信息的功能，但任何时候您都不得以本协议未明确约定或柏斯融媒体科技未书面许可的方式屏蔽、过滤广告信息。柏斯融媒体科技依照法律的规定对广告商履行相关义务，您应当自行判断广告信息的真实性并为自己的判断行为负责，除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，柏斯融媒体科技不承担责任。您同意，对柏斯融媒体科技服务中出现的广告信息，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对依该广告信息进行的交易负责。
        <br />
        2、柏斯融媒体科技的部分服务是以收费方式提供的，如您使用收费服务，请遵守相关的协议。柏斯融媒体科技可能根据实际需要对收费服务的收费标准、方式进行修改和变更，柏斯融媒体科技也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，柏斯融媒体科技将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。
      </div>
      <h2 class="ul-title pt-20 pb-10">九、不可抗力及其他免责事由</h2>
      <div class="mrow-10">
        1、您理解并同意，在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，柏斯融媒体科技将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失柏斯融媒体科技在法律允许的范围内免责。
        <br />
        2、在法律允许的范围内，柏斯融媒体科技对以下情形导致的服务中断或受阻不承担责任：
        <div class="mrow-10 tip">
          （1）受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏；
          <br />
          （2）用户或柏斯融媒体科技的电脑软件、系统、硬件和通信线路出现故障；
          <br />
          （3）用户操作不当；
          <br />
          （4）用户通过非柏斯融媒体科技授权的方式使用本服务；
          <br />
          （5）其他柏斯融媒体科技无法控制或合理预见的情形。
        </div>
        3、您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，柏斯融媒体科技不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
        <div class="mrow-10 tip">
          （1）来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息；
          <br />
          （2）因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
          <br />
          （3）其他因网络信息或用户行为引起的风险。
        </div>
        4、您理解并同意，本服务并非为某些特定目的而设计，包括但不限于核设施、军事用途、医疗设施、交通通讯等重要领域。如果因为软件或服务的原因导致上述操作失败而带来的人员伤亡、财产损失和环境破坏等，柏斯融媒体科技不承担法律责任。
        <br />
        5、柏斯融媒体科技依据本协议约定获得处理违法违规内容的权利，该权利不构成柏斯融媒体科技的义务或承诺，柏斯融媒体科技不能保证及时发现违法行为或进行相应处理。
        <br />
        6、在任何情况下，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意有关防范诈骗犯罪的提示。
      </div>
      <h2 class="ul-title pt-20 pb-10">十、协议的生效与变更</h2>
      <div class="mrow-10">
        1、您使用柏斯融媒体科技的服务即视为您已阅读本协议并接受本协议的约束。柏斯融媒体科技有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。本协议条款变更后，如果您继续使用柏斯融媒体科技提供的软件或服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用柏斯融媒体科技提供的软件或服务。
        <br />
        2、柏斯融媒体科技可能会对服务内容进行变更，也可能会中断、中止或终止服务。您理解并同意，柏斯融媒体科技有权自主决定经营策略。在柏斯融媒体科技发生合并、分立、收购、资产转让时，柏斯融媒体科技可向第三方转让本服务下相关资产；柏斯融媒体科技也可在单方通知您后，将本协议下部分或全部服务转交由第三方运营或履行。具体受让主体以柏斯融媒体科技通知的为准。
        <br />
        3、如发生下列任何一种情形，柏斯融媒体科技有权不经通知而中断或终止向您提供的服务：
        <div class="mrow-10 tip">
          （1）根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；
          <br />
          （2）您违反相关法律法规或本协议的约定；
          <br />
          （3）按照法律规定或主管部门的要求；
          <br />
          （4）出于安全的原因或其他必要的情形。
        </div>
        4、柏斯融媒体科技有权按本协议约定进行收费。若您未按时足额付费，柏斯融媒体科技有权中断、中止或终止提供服务。
        <br />
        5、您有责任自行备份存储在本服务中的数据。如果您的服务被终止，柏斯融媒体科技可以从服务器上永久地删除您的数据,但法律法规另有规定的除外。服务终止后，柏斯融媒体科技没有义务向您返还数据。
      </div>
      <h2 class="ul-title pt-20 pb-10">十一、管辖与法律适用</h2>
      <div class="mrow-10">
        1、本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
        <br />
        2、本协议签订地为中华人民共和国北京市东城区。
        <br />
        3、若您和柏斯融媒体科技之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地（即中华人民共和国北京市东城区）有管辖权的人民法院管辖。
        <br />
        4、本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
        <br />
        5、本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
      </div>
      <h2 class="ul-title pt-20 pb-10">十二、未成年人使用条款</h2>
      <div class="mrow-10">
        1、若用户未满18周岁，则为未成年人，应在监护人监护、指导下阅读本协议和使用本服务。
        <br />
        2、未成年人用户涉世未深，容易被网络虚象迷惑，且好奇心强，遇事缺乏随机应变的处理能力，很容易被别有用心的人利用而又缺乏自我保护能力。因此，未成年人用户在使用本服务时应注意以下事项，提高安全意识，加强自我保护：
        <div class="mrow-10 tip">
          （1）认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活；
          <br />
          （2）填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰；
          <br />
          （3）在监护人或老师的指导下，学习正确使用网络；
          <br />
          （4）避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全。
        </div>
        3、监护人、学校均应对未成年人使用本服务时多做引导。特别是家长应关心子女的成长，注意与子女的沟通，指导子女上网应该注意的安全问题，防患于未然。
      </div>
      <h2 class="ul-title pt-20 pb-10">十三、其他</h2>
      <div class="mrow-10 ti-1">
        如果您对本协议或本服务有意见或建议，可与柏斯融媒体科技联系，我们会给予您必要的帮助。
        本协议的最终解释权归广东柏斯融媒体科技有限公司所有。
        如有疑问请联系 youxiangle202163@163.com
      </div>
    </div>

    <div class="ti-1 pre-txt mt-10">&nbsp;</div>
    <div class="ti-1 pre-txt mt-10">
      <a href="/yxl-web/app/launch-app?backgroundColor=white">返回</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceAgreement'
};
</script>

<style lang="scss" scoped>
.title,
.txt {
  font-size: 0.24rem;
  color: #0a1738;
  line-height: 0.4rem;
  letter-spacing: 0.02rem;
  .ul-title {
    font-size: 0.28rem;
    font-weight: bold;
  }
  .li-title {
    font-size: 0.24rem;
  }
}
.title {
  font-size: 0.3rem;
  font-weight: bold;
}
.ti-1 {
  text-indent: 1em;
}
.ti-2 {
  text-indent: 2em;
}
.tip {
  font-size: 0.22rem;
}
</style>
